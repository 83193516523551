export interface IVCardPhoto {
  url?: string;
}

export interface IVCardMailingAddress {
  label?: string;
  poBox?: string;
  extendedAddress?: string;
  street?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  countryRegion?: string;
}

export interface IVCardSocialUrls {
  Facebook?: string;
  Twitter?: string;
  Instagram?: string;
  WeChat?: string;
  LinkedIn?: string;
  Spotify?: string;
  Tiktok?: string;
  Youtube?: string;
}

export interface IVCardAddress {
  type: EVCardAddressType;
  details?: IVCardMailingAddress;
}

export enum EVCardAddressType {
  HOME = 'HOME',
  WORK = 'WORK',
}

export interface IVCardFormattingOptions {
  skipCharset?: boolean;
  skipShowAs?: boolean;
  skipRevision?: boolean;
  skipPhoto?: boolean;
}
