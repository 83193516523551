import * as fs from 'fs';
import {
  IVCardFormattingOptions,
  IVCardMailingAddress,
  IVCardPhoto,
  IVCardSocialUrls,
} from './interfaces';
// eslint-disable-next-line import/no-cycle
import { VCardFormatter } from './vcard-formatter';

export class VCard {
  public uid?: string;

  public anniversary?: Date;

  public birthday?: Date;

  public cellPhone?: string | string[];

  public pagerPhone?: string | string[];

  public email?: string | string[];

  public workEmail?: string | string[];

  public firstName?: string;

  public formattedName?: string;

  public gender?: string;

  public homeAddress?: IVCardMailingAddress;

  public homePhone?: string | string[];

  public homeFax?: string | string[];

  public lastName?: string;

  public logo?: IVCardPhoto;

  public middleName?: string;

  public namePrefix?: string;

  public nameSuffix?: string;

  public nickname?: string;

  public note?: string;

  public organization?: string;

  public otherEmail?: string | string[];

  public otherPhone?: string | string[];

  public photo?: IVCardPhoto;

  public role?: string;

  public socialUrls?: IVCardSocialUrls;

  public source?: string;

  public title?: string;

  public url?: string;

  public workUrl?: string;

  public workAddress?: IVCardMailingAddress;

  public workPhone?: string | string[];

  public workFax?: string | string[];

  public version?: string = '3.0';

  public getMajorVersion(): number {
    const majorVersionString = this.version ? this.version.split('.')[0] : '4';

    if (!Number.isNaN(Number(majorVersionString))) {
      return Number(majorVersionString);
    }
    return 4;
  }

  public async getFormattedString(
    options?: IVCardFormattingOptions,
  ): Promise<string> {
    return VCardFormatter.getFormattedString(this, options);
  }

  public async saveToFile(filename: string) {
    const contents = await VCardFormatter.getFormattedString(this);
    fs.writeFileSync(filename, contents, { encoding: 'utf8' });
  }
}
