export enum EQrPatternShape {
  DOTS = 'dots',
  ROUNDED = 'rounded',
  CLASSY = 'classy',
  CLASSY_ROUNDED = 'classy_rounded',
  SQUARE = 'square',
  EXTRA_ROUNDED = 'extra_rounded',
}

export enum EQrCornerShape {
  SQUARE_AND_SQUARE = 'square_and_square',
  SQUARE_AND_DOT = 'square_and_dot',
  ROUNDED_AND_SQUARE = 'rounded_and_square',
  ROUNDED_AND_DOT = 'rounded_and_dot',
  CIRCLE_AND_SQUARE = 'circle_and_square',
  CIRCLE_AND_DOT = 'circle_and_dot',
}

export interface ILinkQrConfigLogo {
  name: string;
  color: string;
}

export interface ILinkQrConfig {
  patternColor: string;
  patternShape: EQrPatternShape;
  cornerColor: string;
  cornerShape: EQrCornerShape;
  image?: string;
  logo?: ILinkQrConfigLogo;
}
