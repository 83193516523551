import {
  ELinkType,
  ILinkConfigFacebook,
  ILinkConfigInstagram,
  ILinkConfigLinkedIn,
  ILinkConfigTikTok,
  ILinkConfigTwitter,
  ILinkConfigWeChat,
} from '@connect-saas/types';
import { isURL } from 'class-validator';

type TSocialMediaLinkConfig =
  | ILinkConfigTikTok
  | ILinkConfigTwitter
  | ILinkConfigWeChat
  | ILinkConfigInstagram
  | ILinkConfigFacebook
  | ILinkConfigLinkedIn;

export const facebookUrl = (
  profileOrUrl: string,
  type: 'share' | 'profile',
): string => {
  let url: string;
  switch (type) {
    case 'share':
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        profileOrUrl,
      )}`;
      break;
    case 'profile':
      url = isURL(profileOrUrl, { require_protocol: true })
        ? profileOrUrl
        : `https://www.facebook.com/${profileOrUrl}`;
      break;
    default:
      url = '';
  }

  return url;
};

export const instagramUrl = (profileOrUrl: string): string => {
  if (isURL(profileOrUrl, { require_protocol: true })) {
    return profileOrUrl;
  }
  return `https://instagram.com/${profileOrUrl}`;
};

export const twitterUrl = (
  profileOrUrl: string,
  type: 'share' | 'profile',
): string => {
  let url: string;
  switch (type) {
    case 'share':
      url = `https://www.twitter.com/intent/tweet?url=${encodeURIComponent(
        profileOrUrl,
      )}`;
      break;
    case 'profile':
      url = isURL(profileOrUrl, { require_protocol: true })
        ? profileOrUrl
        : `https://www.twitter.com/${profileOrUrl}`;
      break;
    default:
      url = '';
  }
  return url;
};

export const tiktokUrl = (profileOrUrl: string): string => {
  if (isURL(profileOrUrl, { require_protocol: true })) {
    return profileOrUrl;
  }

  return `https://tiktok.com/${profileOrUrl}`;
};

export const weChatUrl = (profileOrUrl: string): string => {
  if (isURL(profileOrUrl, { require_protocol: true })) {
    return profileOrUrl;
  }

  return `https://wechat.com/u/${profileOrUrl}`;
};

export const linkedInUrl = (profileOrUrl: string): string => {
  if (isURL(profileOrUrl, { require_protocol: true })) {
    return profileOrUrl;
  }

  return `https://www.linkedin.com/in/${profileOrUrl}`;
};

/**
 * Get a Social Medial Url from config
 *
 * @param {TSocialMediaLinkConfig} config
 * @returns {string}
 */
export const getSocialMediaUrl = (config: TSocialMediaLinkConfig): string => {
  let url: string;
  switch (config.type) {
    case ELinkType.LINKEDIN:
      url = linkedInUrl(config.profile);
      break;
    case ELinkType.TIKTOK:
      url = tiktokUrl(config.profile);
      break;
    case ELinkType.TWITTER:
      url = twitterUrl(config.profileOrUrl, config.subType);
      break;
    case ELinkType.WECHAT:
      url = weChatUrl(config.profile);
      break;
    case ELinkType.INSTAGRAM:
      url = instagramUrl(config.profile);
      break;
    case ELinkType.FACEBOOK:
      url = facebookUrl(config.profileOrUrl, config.subType);
      break;
    default:
      url = '';
  }

  return url;
};
