<template>
  <div id="static-qr-app" class="application theme--light">
    <v-app>
      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <component :is="Component" />
        </Transition>
      </router-view>
    </v-app>
  </div>
</template>
