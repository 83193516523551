import { ILinkConfigContact } from '@connect-saas/types';
import { IVCardFormattingOptions, VCard } from './vcard';
import {
  facebookUrl,
  instagramUrl,
  linkedInUrl,
  tiktokUrl,
  twitterUrl,
  weChatUrl,
} from './social-media-url';

/**
 * Get VCard formatted string
 *
 * @param {ILinkConfigContact} config
 * @param {IVCardFormattingOptions} options
 * @returns {Promise<string>}
 */
export const getVCardContent = async (
  config: ILinkConfigContact,
  options?: IVCardFormattingOptions,
): Promise<string> => {
  const vCard = new VCard();
  vCard.firstName = config.firstName;
  vCard.lastName = config.lastName;
  vCard.organization = config.organization || '';
  vCard.title = config.position || '';
  vCard.note = config.description || '';

  if (config.home) {
    vCard.homeAddress = {
      street: config.home.street,
      city: config.home.city,
      stateProvince: config.home.state,
      postalCode: config.home.zipcode,
      countryRegion: config.home.country,
    };

    vCard.homePhone = config.home.phone;
    vCard.homeFax = config.home.fax;
    vCard.url = config.home.website;
    vCard.email = config.home.email;
    vCard.cellPhone = config.home.mobile;
  }

  if (config.work) {
    vCard.workAddress = {
      street: config.work.street,
      city: config.work.city,
      stateProvince: config.work.state,
      postalCode: config.work.zipcode,
      countryRegion: config.work.country,
    };

    vCard.workEmail = config.work.email;
    vCard.workPhone = config.work.phone;
    vCard.workFax = config.work.fax;
    vCard.workUrl = config.work.website;
    vCard.cellPhone = config.work.mobile;
  }

  vCard.photo = {
    url: config.image || '',
  };

  if (config.socialProfiles && Object.keys(config.socialProfiles).length) {
    vCard.socialUrls = {};

    vCard.socialUrls.Facebook = config.socialProfiles?.facebook
      ? facebookUrl(config.socialProfiles?.facebook, 'profile')
      : undefined;

    vCard.socialUrls.Instagram = config.socialProfiles?.instagram
      ? instagramUrl(config.socialProfiles?.instagram)
      : undefined;

    vCard.socialUrls.LinkedIn = config.socialProfiles?.linkedIn
      ? linkedInUrl(config.socialProfiles?.linkedIn)
      : undefined;

    vCard.socialUrls.Spotify = config.socialProfiles?.spotify || undefined;

    vCard.socialUrls.Tiktok = config.socialProfiles?.tiktok
      ? tiktokUrl(config.socialProfiles?.tiktok)
      : undefined;

    vCard.socialUrls.Twitter = config.socialProfiles?.twitter
      ? twitterUrl(config.socialProfiles?.twitter, 'profile')
      : undefined;

    vCard.socialUrls.WeChat = config.socialProfiles?.weChat
      ? weChatUrl(config.socialProfiles?.weChat)
      : undefined;

    vCard.socialUrls.Youtube = config.socialProfiles?.youtube || undefined;
  }
  return vCard.getFormattedString(options);
};
