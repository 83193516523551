/* eslint-disable */
import axios from 'axios';
import {
  EVCardAddressType,
  IVCardAddress,
  IVCardFormattingOptions,
} from './interfaces';
import { VCard } from './vcard';

export class VCardFormatter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static e(value: any): string {
    if (value) {
      if (typeof value !== 'string') {
        value = `${value}`;
      }
      return value
        .replace(/\n/g, '\\n')
        .replace(/,/g, '\\,')
        .replace(/;/g, '\\;');
    }
    return '';
  }

  private static nl(): string {
    return '\r\n';
  }

  private static getFormattedPhoto(
    photoType: string,
    url: string,
    majorVersion: number,
    base64: boolean,
    mediaType?: string,
  ): string {
    let params;

    if (majorVersion >= 4) {
      params = base64 ? ';ENCODING=b;MEDIATYPE=image/' : ';MEDIATYPE=image/';
    } else if (majorVersion === 3) {
      params = base64 ? ';ENCODING=b;TYPE=' : ';TYPE=';
    } else {
      params = base64 ? ';ENCODING=BASE64;' : ';';
    }

    return `${photoType + params + mediaType}:${VCardFormatter.e(
      url,
    )}${VCardFormatter.nl()}`;
  }

  private static getFormattedAddress(
    encodingPrefix: string,
    address: IVCardAddress,
    majorVersion: number,
  ): string {
    if (!address.details) return '';

    let formattedAddress = '';

    if (
      address.details.label ||
      address.details.street ||
      address.details.city ||
      address.details.stateProvince ||
      address.details.postalCode ||
      address.details.countryRegion
    ) {
      if (majorVersion >= 4) {
        formattedAddress = `ADR${encodingPrefix};TYPE=${address.type}${
          address.details.label
            ? `;LABEL="${VCardFormatter.e(address.details.label)}"`
            : ''
        }:${VCardFormatter.e(address.details.poBox)};${VCardFormatter.e(
          address.details.extendedAddress,
        )};${VCardFormatter.e(address.details.street)};${VCardFormatter.e(
          address.details.city,
        )};${VCardFormatter.e(
          address.details.stateProvince,
        )};${VCardFormatter.e(address.details.postalCode)};${VCardFormatter.e(
          address.details.countryRegion,
        )}${VCardFormatter.nl()}`;
      } else {
        if (address.details.label) {
          formattedAddress = `LABEL${encodingPrefix};TYPE=${
            address.type
          }:${VCardFormatter.e(address.details.label)}${VCardFormatter.nl()}`;
        }
        formattedAddress += `ADR${encodingPrefix};TYPE=${
          address.type
        }:;;${VCardFormatter.e(address.details.street)};${VCardFormatter.e(
          address.details.city,
        )};${VCardFormatter.e(
          address.details.stateProvince,
        )};${VCardFormatter.e(address.details.postalCode)};${VCardFormatter.e(
          address.details.countryRegion,
        )}${VCardFormatter.nl()}`;
      }
    }
    return formattedAddress;
  }

  private static getFormattedDate(date: Date): string {
    return (
      date.getFullYear() +
      `0${date.getMonth() + 1}`.slice(-2) +
      `0${date.getDate()}`.slice(-2)
    );
  }

  public static async convertPhotoUrlToBase64(url: string): Promise<string> {
    try {
      const response = await axios.get(url, {
        responseType: 'arraybuffer',
      });
      const base64Image = Buffer.from(response.data, 'binary').toString(
        'base64',
      );
      return base64Image;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  public static async getFormattedString(
    vCard: VCard,
    options?: IVCardFormattingOptions,
  ): Promise<string> {
    if (options === undefined) {
      // set defaults
      options = { skipCharset: false, skipShowAs: false, skipRevision: false };
    }

    const majorVersion = vCard.getMajorVersion();

    let formattedVCardString = '';
    formattedVCardString += `BEGIN:VCARD${VCardFormatter.nl()}`;
    formattedVCardString += `VERSION:${vCard.version}${VCardFormatter.nl()}`;

    const encodingPrefix =
      majorVersion >= 3 || options.skipCharset ? '' : ';CHARSET=UTF-8';
    let formattedName = vCard.formattedName || '';

    if (!formattedName) {
      [vCard.firstName, vCard.middleName, vCard.lastName]
        .map((n) => n || '')
        .forEach(function (name: string) {
          if (name) {
            if (formattedName) {
              formattedName += ' ';
            }
          }
          formattedName += name;
        });
    }

    formattedVCardString += `FN${encodingPrefix}:${VCardFormatter.e(
      formattedName,
    )}${VCardFormatter.nl()}`;
    formattedVCardString += `N${encodingPrefix}:${VCardFormatter.e(
      vCard.lastName,
    )};${VCardFormatter.e(vCard.firstName)};${VCardFormatter.e(
      vCard.middleName,
    )};${VCardFormatter.e(vCard.namePrefix)};${VCardFormatter.e(
      vCard.nameSuffix,
    )}${VCardFormatter.nl()}`;

    if (vCard.nickname && majorVersion >= 3) {
      formattedVCardString += `NICKNAME${encodingPrefix}:${VCardFormatter.e(
        vCard.nickname,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.gender) {
      formattedVCardString += `GENDER:${VCardFormatter.e(vCard.gender)}${VCardFormatter.nl()}`;
    }

    if (vCard.uid) {
      formattedVCardString += `UID${encodingPrefix}:${VCardFormatter.e(
        vCard.uid,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.birthday) {
      formattedVCardString += `BDAY:${VCardFormatter.getFormattedDate(
        vCard.birthday,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.anniversary) {
      formattedVCardString += `ANNIVERSARY:${VCardFormatter.getFormattedDate(
        vCard.anniversary,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.email) {
      if (!Array.isArray(vCard.email)) {
        vCard.email = [vCard.email];
      }
      vCard.email.forEach(function (address) {
        if (majorVersion >= 4) {
          formattedVCardString += `EMAIL${encodingPrefix};type=HOME:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        } else if (majorVersion >= 3 && majorVersion < 4) {
          formattedVCardString += `EMAIL${encodingPrefix};type=HOME,INTERNET:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `EMAIL${encodingPrefix};HOME;INTERNET:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.workEmail) {
      if (!Array.isArray(vCard.workEmail)) {
        vCard.workEmail = [vCard.workEmail];
      }
      vCard.workEmail.forEach(function (address) {
        if (majorVersion >= 4) {
          formattedVCardString += `EMAIL${encodingPrefix};type=WORK:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        } else if (majorVersion >= 3 && majorVersion < 4) {
          formattedVCardString += `EMAIL${encodingPrefix};type=WORK,INTERNET:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `EMAIL${encodingPrefix};WORK;INTERNET:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.otherEmail) {
      if (!Array.isArray(vCard.otherEmail)) {
        vCard.otherEmail = [vCard.otherEmail];
      }
      vCard.otherEmail.forEach(function (address) {
        if (majorVersion >= 4) {
          formattedVCardString += `EMAIL${encodingPrefix};type=OTHER:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        } else if (majorVersion >= 3 && majorVersion < 4) {
          formattedVCardString += `EMAIL${encodingPrefix};type=OTHER,INTERNET:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `EMAIL${encodingPrefix};OTHER;INTERNET:${VCardFormatter.e(
            address,
          )}${VCardFormatter.nl()}`;
        }
      });
    }

    if (!options?.skipPhoto) {
      if (vCard.logo && vCard.logo.url) {
        formattedVCardString += VCardFormatter.getFormattedPhoto(
          'LOGO',
          await VCardFormatter.convertPhotoUrlToBase64(vCard.logo.url),
          majorVersion,
          true,
          'PNG',
        );
      }

      if (vCard.photo && vCard.photo.url) {
        formattedVCardString += VCardFormatter.getFormattedPhoto(
          'PHOTO',
          await VCardFormatter.convertPhotoUrlToBase64(vCard.photo.url),
          majorVersion,
          true,
          'PNG',
        );
      }
    }

    if (vCard.cellPhone) {
      if (!Array.isArray(vCard.cellPhone)) {
        vCard.cellPhone = [vCard.cellPhone];
      }
      vCard.cellPhone.forEach(function (number) {
        if (majorVersion >= 4) {
          formattedVCardString += `TEL;VALUE=uri;TYPE="voice,cell":tel:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `TEL;TYPE=CELL:${VCardFormatter.e(number)}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.pagerPhone) {
      if (!Array.isArray(vCard.pagerPhone)) {
        vCard.pagerPhone = [vCard.pagerPhone];
      }
      vCard.pagerPhone.forEach(function (number) {
        if (majorVersion >= 4) {
          formattedVCardString += `TEL;VALUE=uri;TYPE="pager,cell":tel:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `TEL;TYPE=PAGER:${VCardFormatter.e(number)}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.homePhone) {
      if (!Array.isArray(vCard.homePhone)) {
        vCard.homePhone = [vCard.homePhone];
      }
      vCard.homePhone.forEach(function (number) {
        if (majorVersion >= 4) {
          formattedVCardString += `TEL;VALUE=uri;TYPE="voice,home":tel:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `TEL;TYPE=HOME,VOICE:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.workPhone) {
      if (!Array.isArray(vCard.workPhone)) {
        vCard.workPhone = [vCard.workPhone];
      }
      vCard.workPhone.forEach(function (number) {
        if (majorVersion >= 4) {
          formattedVCardString += `TEL;VALUE=uri;TYPE="voice,work":tel:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `TEL;TYPE=WORK,VOICE:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.homeFax) {
      if (!Array.isArray(vCard.homeFax)) {
        vCard.homeFax = [vCard.homeFax];
      }
      vCard.homeFax.forEach(function (number) {
        if (majorVersion >= 4) {
          formattedVCardString += `TEL;VALUE=uri;TYPE="fax,home":tel:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `TEL;TYPE=HOME,FAX:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.workFax) {
      if (!Array.isArray(vCard.workFax)) {
        vCard.workFax = [vCard.workFax];
      }
      vCard.workFax.forEach(function (number) {
        if (majorVersion >= 4) {
          formattedVCardString += `TEL;VALUE=uri;TYPE="fax,work":tel:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `TEL;TYPE=WORK,FAX:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.otherPhone) {
      if (!Array.isArray(vCard.otherPhone)) {
        vCard.otherPhone = [vCard.otherPhone];
      }
      vCard.otherPhone.forEach(function (number) {
        if (majorVersion >= 4) {
          formattedVCardString += `TEL;VALUE=uri;TYPE="voice,other":tel:${VCardFormatter.e(
            number,
          )}${VCardFormatter.nl()}`;
        } else {
          formattedVCardString += `TEL;TYPE=OTHER:${VCardFormatter.e(number)}${VCardFormatter.nl()}`;
        }
      });
    }

    [
      {
        details: vCard.homeAddress,
        type: EVCardAddressType.HOME,
      },
      {
        details: vCard.workAddress,
        type: EVCardAddressType.WORK,
      },
    ]
      .filter((a) => a.details)
      .forEach(function (address) {
        formattedVCardString += VCardFormatter.getFormattedAddress(
          encodingPrefix,
          address,
          majorVersion,
        );
      });

    if (vCard.title) {
      formattedVCardString += `TITLE${encodingPrefix}:${VCardFormatter.e(
        vCard.title,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.role) {
      formattedVCardString += `ROLE${encodingPrefix}:${VCardFormatter.e(
        vCard.role,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.organization) {
      formattedVCardString += `ORG${encodingPrefix}:${VCardFormatter.e(
        vCard.organization,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.url) {
      formattedVCardString += `URL${encodingPrefix}:${VCardFormatter.e(
        vCard.url,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.workUrl) {
      formattedVCardString += `URL;type=WORK${encodingPrefix}:${VCardFormatter.e(
        vCard.workUrl,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.note) {
      formattedVCardString += `NOTE${encodingPrefix}:${VCardFormatter.e(
        vCard.note,
      )}${VCardFormatter.nl()}`;
    }

    if (vCard.socialUrls) {
      const keys = Object.keys(vCard.socialUrls);
      keys.forEach((key) => {
        if (vCard.socialUrls[key]) {
          formattedVCardString += `X-SOCIALPROFILE;TYPE=${key}:${VCardFormatter.e(
            vCard.socialUrls[key],
          )}${VCardFormatter.nl()}`;
        }
      });
    }

    if (vCard.source) {
      formattedVCardString += `SOURCE${encodingPrefix}:${VCardFormatter.e(
        vCard.source,
      )}${VCardFormatter.nl()}`;
    }

    if (!options.skipRevision) {
      formattedVCardString += `REV:${new Date().toISOString()}${VCardFormatter.nl()}`;
    }

    if (vCard.organization && !options.skipShowAs) {
      formattedVCardString += `X-ABShowAs:COMPANY${VCardFormatter.nl()}`;
    }

    formattedVCardString += `END:VCARD${VCardFormatter.nl()}`;
    return formattedVCardString;
  }
}
