import { EQrPatternShape } from '@connect-saas/types';
import QRCodeStyling from '@ypbconnectplatform/qrcode-vue3/src/core/QRCodeStyling';
import { computed } from 'vue';
import { ILinkStaticQrConfigStyle } from '../modules/qr-generator/qr-generator.interface';
import checkColor from './color';

const useQrPreview = async (
  styleData: ILinkStaticQrConfigStyle,
  content: string,
  qrOptions: unknown,
  imageOptions: unknown,
  width: number,
  height: number,
  qrDownloadOptions: unknown,
) => {
  const patternReplaceList: Record<string, string> = {
    extra_rounded: 'extra-rounded',
    classy_rounded: 'classy-rounded',
  };
  const cornerReplaceList: Record<string, string> = {
    rounded: 'extra-rounded',
    circle: 'dot',
  };

  const corners = computed(() => {
    if (styleData?.cornerShape) {
      const substringArray = styleData?.cornerShape.split('_');
      const square = substringArray[0];
      const dot = substringArray[substringArray.length - 1];
      let cornerSquare = square;
      let cornerDots = dot;
      if (cornerReplaceList[square]) {
        cornerSquare = cornerReplaceList[square];
      }
      if (cornerReplaceList[dot]) {
        cornerDots = cornerReplaceList[dot];
      }
      return {
        cornerSquare,
        cornerDots,
      };
    }
    return null;
  });
  const dotsOptions = computed(() => {
    let shape: string = styleData?.patternShape ?? EQrPatternShape.SQUARE;
    if (patternReplaceList[shape]) {
      shape = patternReplaceList[shape];
    }
    return {
      type: shape,
      color: checkColor(styleData?.patternColor),
    };
  });
  const cornersSquareOptions = computed(() => ({
    type: corners.value?.cornerSquare ?? EQrPatternShape.SQUARE,
    color: checkColor(styleData?.cornerColor),
  }));
  const cornersDotOptions = computed(() => ({
    type: corners.value?.cornerDots ?? EQrPatternShape.SQUARE,
    color: checkColor(styleData?.cornerColor),
  }));

  const covertImageToBase64 = async (file: File): Promise<string> => {
    const reader = new FileReader();
    return await new Promise((resolve, reject) => {
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Failed to read image data'));
        }
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const image = computed(() => {
    if (styleData?.image) {
      return covertImageToBase64(styleData?.image);
    }
    return null;
  });

  const downloadQrCode = async () => {
    const qrCode = new QRCodeStyling({
      data: content,
      width,
      height,
      margin: 0,
      qrOptions,
      imageOptions,
      dotsOptions: dotsOptions.value,
      backgroundOptions: { color: '#ffffff' },
      image: await image.value,
      cornersSquareOptions: cornersSquareOptions.value,
      cornersDotOptions: cornersDotOptions.value,
    });
    await qrCode.download(qrDownloadOptions);
  };

  return {
    dots: dotsOptions.value,
    cornersSquare: cornersSquareOptions.value,
    cornersDot: cornersDotOptions.value,
    image: await image.value,
    downloadQrCode,
  };
};

export default useQrPreview;
