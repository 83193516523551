import { ELinkType, TLinkStaticQrConfigs } from '@connect-saas/types';
import { getSocialMediaUrl } from './social-media-url';
import { email, location, phone, sms, wifi } from './providers';
import { getVCardContent } from './vcard-content';

export const getStaticContent = async (
  config: TLinkStaticQrConfigs,
): Promise<string> => {
  let content: string;
  switch (config.type) {
    case ELinkType.SPOTIFY:
    case ELinkType.YOUTUBE:
    case ELinkType.URL:
      content = config.url;
      break;
    case ELinkType.INSTAGRAM:
    case ELinkType.FACEBOOK:
    case ELinkType.TWITTER:
    case ELinkType.WECHAT:
    case ELinkType.LINKEDIN:
    case ELinkType.TIKTOK:
      content = getSocialMediaUrl(config);
      break;
    case ELinkType.TEXT:
      content = config.text;
      break;
    case ELinkType.BUSINESS_CARD:
    case ELinkType.CONTACT:
      content = await getVCardContent(config, {
        skipPhoto: true,
        skipShowAs: true,
      });
      break;
    case ELinkType.EMAIL:
      content = email(config);
      break;
    case ELinkType.LOCATION:
      content = location(config);
      break;
    case ELinkType.PHONE:
      content = phone(config);
      break;
    case ELinkType.SMS:
      content = sms(config);
      break;
    case ELinkType.WIFI:
      content = wifi(config);
      break;
    default:
      return '';
  }

  return content;
};
