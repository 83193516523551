<script setup lang="ts">
import { computed } from 'vue';
import { TButtonVariant } from '../../modules/common/common.type';

interface Props {
  color?: string;
  content?: string;
  loading?: boolean;
  disabled?: boolean;
  variant?: TButtonVariant;
  block?: boolean;
  icon?: boolean;
  capitalize?: boolean;
  size?: string | number;
  iconPosition?: string;
  iconName?: string;
  iconSize?: string | number;
  iconColor?: string;
  dashed?: boolean;
  href?: string;
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary',
  content: '',
  loading: false,
  disabled: false,
  variant: 'flat',
  block: false,
  icon: false,
  capitalize: false,
  size: 'default',
  iconPosition: 'left',
  iconName: null,
  iconSize: 24,
  iconColor: '',
  dashed: false,
  href: null,
});

const emit = defineEmits<{
  (e: 'on-button-click'): void;
}>();

const leftIcon = computed(() =>
  props.iconPosition === 'left' ? props.iconName : null,
);
const rightIcon = computed(() =>
  props.iconPosition === 'right' ? props.iconName : null,
);

const buttonClick = () => {
  emit('on-button-click');
};
</script>

<template>
  <v-btn
    :color="color"
    :loading="loading"
    :disabled="disabled"
    :block="block"
    :icon="icon"
    :size="size"
    :variant="variant"
    :class="[
      'base-button',
      { rounded: !icon },
      { 'text-capitalize': capitalize },
      { 'border-dashed': dashed },
    ]"
    :prepend-icon="leftIcon"
    :append-icon="rightIcon"
    :href="href"
    :target="href ? '_blank' : null"
    @click="buttonClick"
  >
    <v-icon
      v-if="icon"
      class="notranslate"
      :size="iconSize"
      :color="iconColor"
      :icon="iconName"
    ></v-icon>
    <slot name="custom" />
    <span v-if="!icon">{{ content }}</span>
    <template #append>
      <slot name="appendcontent" />
    </template>
  </v-btn>
</template>

<style lang="scss" scoped>
.base-button {
  :deep(.v-btn__append:empty) {
    display: none;
  }
}
</style>
