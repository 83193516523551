import { createGtm } from '@gtm-support/vue-gtm';
import { createApp } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import 'vue-svg-inline-plugin/src/polyfills';
import App from './app/App.vue';
import './app/plugins/vee-validate';
import vuetify from './app/plugins/vuetify';
import './assets/scss/main.scss';
import router from './router/index';
import * as Sentry from '@sentry/vue';

const isProduction = import.meta.env.MODE === 'production';
const gtmId = isProduction ? 'GTM-KCL696RZ' : 'GTM-5DLNCSZX';

const app = createApp(App);
app.use(vuetify);
app.use(router);
app.use(VueSvgInlinePlugin);
app.use(
  createGtm({
    id: gtmId,
    vueRouter: router,
  }),
);
app.use(VueDOMPurifyHTML, {
  hooks: {
    beforeSanitizeAttributes: (node: Element) => {
      if (node.tagName === 'A') {
        if (node.hasAttribute('target')) {
          node.setAttribute(
            'data-temp-href-target',
            node.getAttribute('target'),
          );
        }
      }
    },
    afterSanitizeAttributes: (node: Element) => {
      if (node.tagName === 'A' && node.hasAttribute('data-temp-href-target')) {
        node.setAttribute('target', node.getAttribute('data-temp-href-target'));
        node.removeAttribute('data-temp-href-target');
        if (node.getAttribute('target') === '_blank') {
          node.setAttribute('rel', 'noopener noreferrer');
        }
      }
    },
  },
});
Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, //  Capture 50% of the transactions
  tracePropagationTargets: [/^https:\/\/generator.connectqr\.ai\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
app.mount('#root');
