import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import 'vuetify/styles';

const primaryColor: string = '#040933';

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: primaryColor,
          greendecrease: '#3DD598',
          reddecrease: '#FC5A5A',
          error: '#D72D18',
          'sheen-green-lighten-4': '#686B85',
          'negative-red-lighten-4': '#FBEAE8',
          'honey-yellow-lighten-4': '#FDF5EB',
          'royal-blue-lighten-4': '#ECF0F9',
          'light-green': '#E2EED5',
        },
      },
      dark: {
        dark: true,
        colors: {
          background: '#FFFFFF',
          primary: '#70A838',
        },
      },
    },
  },
});
