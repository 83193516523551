<script setup lang="ts">
import customiseQrImage from '@/assets/images/qr-generator/customise-qr-code.svg';
import {
  ELinkType,
  ILinkConfigContact,
  TLinkStaticQrConfigs,
} from '@connect-saas/types';
import { getStaticContent } from '@connect-saas/util/qr-content';
import BaseButton from '../app/components/base/BaseButton.vue';
import { formatName } from '../app/composables/string';
import { TInitialValue } from '../app/modules/card/card.type';
import {
  ILinkStaticQrConfigStyle,
  IQRData,
  IQRFormats,
} from '../app/modules/qr-generator/qr-generator.interface';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useGtm } from '@gtm-support/vue-gtm';
import { useCloned } from '@vueuse/core';
import { Form } from 'vee-validate';
import {
  Ref,
  computed,
  defineAsyncComponent,
  onMounted,
  ref,
  watchEffect,
} from 'vue';
import useQrPreview from '../app/composables/qrPreview';
import { TLinkConfigs } from '../app/modules/qr-generator/qr-generator.type';
import baseQrConfigComponents from './qrComponents';

const UpsellPopup = defineAsyncComponent(
  () => import('../app/components/base/qr/UpsellPopup.vue'),
);
const QRCommonTypes = defineAsyncComponent(
  () => import('../app/components/base/qr/QRCommonTypes.vue'),
);
const QrFileFormat = defineAsyncComponent(
  () => import('../app/components/base/qr/QrFileFormat.vue'),
);
const QrPreview = defineAsyncComponent(
  () => import('../app/components/base/qr/QrPreview.vue'),
);
const QrStyling = defineAsyncComponent(
  () => import('../app/components/base/qr/QrStyling.vue'),
);
const PowerUpAiButton = defineAsyncComponent(
  () => import('@/app/components/base/qr/PowerUpAiButton.vue'),
);
const setConfigComponentName = (type: string): string => {
  if (!type) {
    return;
  }
  let currentType = type;
  if (type === ELinkType.CONTACT) {
    currentType = ELinkType.BUSINESS_CARD;
  }
  return `BaseQr${formatName(currentType)}Config`;
};

const isSubmitQrLoading = ref(false);
const activeIndex = ref(0);
const panel = ref('setup');
const panel2 = ref('format');
const isSaved = ref(false);
const selectedType: Ref<TLinkConfigs> = ref(null);
const hoveringType: Ref<TLinkConfigs> = ref(null);
const ctaBtnContent = ref('');
const qrData: Ref<IQRData> = ref({
  type: null,
  config: null,
  style: null,
  format: null,
  content: 'Preview',
});
const isPreview = ref(false);
const initialValues: Ref<TInitialValue> = ref({});
const trackQrCodeScan = ref(false);
const showUpsellPopup = ref(false);
const isReset = ref(false);
const dynamicTypeList = [
  ELinkType.BUSINESS_CARD,
  ELinkType.APP,
  ELinkType.LOCATION,
  ELinkType.FORM,
  ELinkType.FILE,
];
const gtm = useGtm();
const getExperimentUpsellCtaValue = localStorage.getItem(
  'wsg_experiment_upsell_cta',
)
  ? localStorage.getItem('wsg_experiment_upsell_cta')
  : null;

const mapCtaBtnContents: { [key: string]: string } = {
  a: 'Start my free trial',
  b: 'Get started for free',
};

const tossCoin = () => {
  const randomVal = Math.random();
  const faceCoin = randomVal < 0.5 ? 'a' : 'b';
  localStorage.setItem('wsg_experiment_upsell_cta', faceCoin);
  ctaBtnContent.value = mapCtaBtnContents[faceCoin];
};

const setCtaBtnContent = () => {
  if (
    getExperimentUpsellCtaValue === 'a' ||
    getExperimentUpsellCtaValue === 'b'
  ) {
    ctaBtnContent.value = mapCtaBtnContents[getExperimentUpsellCtaValue];
  } else {
    tossCoin();
  }
};

const disabledCustomise = computed(() => {
  return dynamicTypeList.includes(selectedType.value?.type);
});

const selectType = (type: TLinkConfigs) => {
  if (isReset.value) {
    isReset.value = false;
  }
  selectedType.value = useCloned(type).cloned.value;
  hoveringType.value = useCloned(type).cloned.value;
  qrData.value.type = type.type;
  qrData.value.config = useCloned(type).cloned.value;
  isPreview.value = false;
  panel.value = 'setup';
  activeIndex.value = 0;
};

const onInputChange = (val: TLinkConfigs) => {
  qrData.value.config = useCloned(val).cloned.value;
  if (val.type === ELinkType.BUSINESS_CARD || val.type === ELinkType.CONTACT) {
    (qrData.value.config as ILinkConfigContact).image = val?.image;
  }
};

const onStyleChanged = (val: ILinkStaticQrConfigStyle) => {
  qrData.value.style = useCloned(val).cloned.value;
  if (val?.image) {
    qrData.value.style.image = val.image;
  }
};

const onFormatChange = (val: IQRFormats) => {
  qrData.value.format = useCloned(val).cloned.value;
};

const submitQrData = async () => {
  try {
    isSubmitQrLoading.value = true;
    qrData.value.content = await getStaticContent(
      qrData.value.config as TLinkStaticQrConfigs,
    );
    isSubmitQrLoading.value = false;
    gtm.trackEvent({
      event: 'finished',
    });
    isSaved.value = true;
    activeIndex.value = 1;
  } catch {
    isSubmitQrLoading.value = false;
  }
};

const onHoverType = (type: TLinkConfigs) => {
  hoveringType.value = useCloned(type).cloned.value;
  qrData.value.type = type.type;
  isPreview.value = true;
};

const popupType: Ref<'ai_qr' | 'dynamic_qr'> = ref('dynamic_qr');
const openUpsellPopup = (type: 'ai_qr' | 'dynamic_qr') => {
  popupType.value = type;
  showUpsellPopup.value = true;
};

const qrOptions = {
  typeNumber: 0,
  mode: 'Byte',
  errorCorrectionLevel: 'H',
};
const imageOptions = {
  hideBackgroundDots: true,
  imageSize: 0.4,
  margin: 0,
};
let downloadQr: Function = null;
const setPreview = async () => {
  const { downloadQrCode } = await useQrPreview(
    qrData.value?.style,
    qrData.value?.content,
    qrOptions,
    imageOptions,
    800,
    800,
    {
      name: 'qr',
      extension: qrData.value?.format?.format,
    },
  );
  downloadQr = downloadQrCode;
};

watchEffect(() => {
  setPreview();
});

const isDownloading = ref(false);
const onDownloadQr = async () => {
  isDownloading.value = true;
  await setPreview();
  await downloadQr();
  gtm.trackEvent({
    event: 'downloaded',
    file_format: qrData.value?.format,
  });
  setTimeout(() => {
    openUpsellPopup('dynamic_qr');
    isDownloading.value = false;
  }, 2000);
};

const redirectToSignup = () => {
  gtm.trackEvent({
    event: 'signup_for_analytics',
  });
  window.open('https://dashboard.connectqr.ai/signup', '_blank');
};

const resetPage = () => {
  gtm.trackEvent({
    event: 'create_another',
    qr_type: 'url',
    code_shape: 'square',
    corner_shape: 'square_and_square',
  });
  isReset.value = true;
};

const trackQrCodeScansPopup = () => {
  gtm.trackEvent({
    event: 'track_qr_code_scans',
  });
  openUpsellPopup('dynamic_qr');
};

const trackPowerUpWithAiPreview = () => {
  gtm.trackEvent({
    event: 'power_up_with_ai_preview',
  });
  openUpsellPopup('ai_qr');
};

const trackPowerUpWithAiFinish = () => {
  gtm.trackEvent({
    event: 'power_up_with_ai_finished',
  });
  openUpsellPopup('ai_qr');
};

const trackCustomization = (value: string) => {
  if (value === 'customise') {
    gtm.trackEvent({
      event: 'customize_qr',
    });
  }
};

onMounted(() => {
  initialValues.value = {
    ...qrData.value.config,
    ...qrData.value.style,
    ...qrData.value.format,
    type: qrData.value.type,
  };
  if (qrData.value.config) {
    if (qrData.value?.config?.type === ELinkType.CONTACT) {
      qrData.value.config.type = ELinkType.BUSINESS_CARD;
    }
    selectType(qrData.value.config);
  }
  gtm.trackEvent({
    event: 'generator_loaded',
    qr_type: 'url',
    code_shape: 'square',
    corner_shape: 'square_and_square',
  });
  setCtaBtnContent();
});
</script>

<template>
  <Form
    :initial-values="initialValues"
    v-slot="{ meta }"
    class="form-container d-flex flex-column"
  >
    <div class="list-qr-type-wrapper bg-white py-4 px-6 overflow-x-auto">
      <QRCommonTypes
        :is-reset="isReset"
        @select:type="selectType"
        @hover:type="onHoverType"
      />
    </div>
    <div
      :class="[
        'qr-generate-wrapper d-flex bg-grey-lighten-5',
        { 'flex-wrap': $vuetify.display.xs },
      ]"
    >
      <div
        :class="[
          'qr-setting w-75',
          { 'h-100': $vuetify.display.smAndUp },
          { 'w-100': activeIndex === 1 || $vuetify.display.smAndDown },
        ]"
      >
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-container fluid class="px-4 pt-2">
                <div v-if="activeIndex === 0">
                  <div v-if="disabledCustomise" class="bg-white px-6 py-2">
                    <component
                      :is="
                        baseQrConfigComponents[
                          setConfigComponentName(selectedType?.type)
                        ]
                      "
                      class="component-container"
                      custom-css="py-4"
                      :field-data="qrData?.config"
                      is-static
                      :cta-content="ctaBtnContent"
                      @change:input="onInputChange"
                    >
                      <template #append>
                        <v-switch
                          inset
                          class="v-active-switch flex-0-0"
                          data-cy="heading-half-width-switch"
                          color="primary"
                          hide-details
                          v-model="trackQrCodeScan"
                          @click="trackQrCodeScansPopup"
                          @update:model-value="trackQrCodeScan = false"
                        >
                          <template v-slot:label>
                            <span class="text-primary"
                              >Track QR code scans (Dynamic QR)</span
                            >
                            <a
                              @click="trackQrCodeScansPopup"
                              class="text-primary ml-2 text-decoration-underline"
                              >Learn more</a
                            >
                          </template>
                        </v-switch>
                      </template>
                    </component>
                  </div>
                  <v-expansion-panels
                    v-model="panel"
                    mandatory
                    eager
                    v-else
                    @update:modelValue="trackCustomization"
                  >
                    <v-expansion-panel value="setup" elevation="0">
                      <v-expansion-panel-title>
                        <h3>Step one</h3>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text class="border-t-sm">
                        <component
                          :is="
                            baseQrConfigComponents[
                              setConfigComponentName(selectedType?.type)
                            ]
                          "
                          class="component-container"
                          custom-css="py-4"
                          :field-data="qrData?.config"
                          is-static
                          @change:input="onInputChange"
                        >
                          <template #append>
                            <v-switch
                              inset
                              class="v-active-switch flex-0-0"
                              data-cy="heading-half-width-switch"
                              color="primary"
                              hide-details
                              v-model="trackQrCodeScan"
                              @click="trackQrCodeScansPopup"
                              @update:model-value="trackQrCodeScan = false"
                            >
                              <template v-slot:label>
                                <div
                                  class="d-inline-flex flex-wrap justify-end"
                                >
                                  <span class="text-primary"
                                    >Track QR code scans (Dynamic QR)</span
                                  >
                                  <a
                                    @click="trackQrCodeScansPopup"
                                    class="text-primary ml-2 text-decoration-underline"
                                    >Learn more</a
                                  >
                                </div>
                              </template>
                            </v-switch>
                          </template>
                        </component>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel
                      value="customise"
                      elevation="0"
                      :disabled="disabledCustomise"
                    >
                      <v-expansion-panel-title>
                        <img
                          :src="customiseQrImage"
                          width="60"
                          alt="Customise QR Code"
                          class="mr-4"
                        />
                        <h3>Customise QR Code</h3>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text class="border-t-sm">
                        <QrStyling
                          :style-data="qrData?.style"
                          @change:input="onStyleChanged"
                        />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <div v-if="activeIndex === 1">
                  <v-expansion-panels
                    class="disabled-expansion-panel"
                    v-model="panel2"
                    eager
                    disabled
                  >
                    <v-expansion-panel
                      elevation="0"
                      hide-actions
                      value="format"
                    >
                      <v-expansion-panel-title readonly>
                        <h3>Congratulations! Your QR Code is ready.</h3>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text class="border-t-sm">
                        <div
                          :class="[
                            'd-flex mt-8 mb-4',
                            { 'flex-column': $vuetify.display.smAndDown },
                          ]"
                        >
                          <div
                            :class="
                              $vuetify.display.smAndDown
                                ? 'w-100'
                                : 'ml-4 mr-12'
                            "
                          >
                            <QrPreview
                              :style-data="qrData.style"
                              :content="qrData?.content"
                              :qr-options="qrOptions"
                              :image-options="imageOptions"
                              :width="150"
                              :height="150"
                            />
                          </div>
                          <div
                            :class="{
                              'w-100 mt-4': $vuetify.display.smAndDown,
                            }"
                          >
                            <div class="max-w-fit">
                              <PowerUpAiButton
                                @open:popup="trackPowerUpWithAiFinish"
                              />
                            </div>
                            <QrFileFormat
                              :format-data="qrData?.format"
                              :cta-content="ctaBtnContent"
                              @change:input="onFormatChange"
                            />
                            <div>
                              <BaseButton
                                v-if="activeIndex === 1"
                                :class="[
                                  'mr-2',
                                  { 'mb-2': $vuetify.display.smAndDown },
                                ]"
                                :disabled="!meta.valid"
                                icon-name="mdi-download"
                                content="Download"
                                data-cy="qr-download-button"
                                :loading="isDownloading"
                                capitalize
                                @on-button-click="onDownloadQr"
                              />
                              <BaseButton
                                v-if="activeIndex === 1"
                                :class="[
                                  'mr-2',
                                  { 'mb-2': $vuetify.display.smAndDown },
                                ]"
                                content="Sign up for Analytics"
                                variant="outlined"
                                data-cy="qr-signup-analytics-button"
                                capitalize
                                @on-button-click="redirectToSignup"
                              />
                              <BaseButton
                                v-if="activeIndex === 1"
                                :class="{ 'mb-2': $vuetify.display.smAndDown }"
                                content="Create another"
                                variant="outlined"
                                data-cy="qr-back-button"
                                capitalize
                                @on-button-click="resetPage"
                              />
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-container>
              <v-container
                fluid
                class="d-flex button-container px-6"
                v-if="!disabledCustomise"
              >
                <v-spacer></v-spacer>
                <BaseButton
                  v-if="activeIndex === 0"
                  :disabled="!meta.valid"
                  content="Finish & Download"
                  data-cy="qr-finish-button"
                  capitalize
                  @on-button-click="submitQrData"
                />
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        v-if="activeIndex !== 1"
        class="qr-preview d-flex justify-center bg-white"
      >
        <div>
          <p class="text-center my-3 font-weight-bold">Preview</p>
          <QrPreview
            :style-data="qrData.style"
            :content="qrData?.content"
            :qr-options="qrOptions"
            :image-options="imageOptions"
          >
            <template #content>
              <div class="px-4">
                <PowerUpAiButton
                  class="mt-2"
                  @open:popup="trackPowerUpWithAiPreview"
                />
              </div>
            </template>
          </QrPreview>
        </div>
      </div>
    </div>
    <UpsellPopup
      :type="popupType"
      :show="showUpsellPopup"
      :cover-background="popupType === 'ai_qr'"
      :cta-content="ctaBtnContent"
      @close="showUpsellPopup = false"
    />
  </Form>
</template>

<style lang="scss" scoped>
.form-container {
  min-height: 100vh;
}
.qr-preview {
  border: 2px solid #f5f5f5;
}
.qr-setting {
  overflow-y: scroll;
}
.list-qr-type-wrapper {
  border-bottom: 2px solid #f5f5f5;
}
.qr-type-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 600;
}
@media (min-width: 601px) {
  .qr-setting {
    width: calc(100% - 380px);
  }
}
@media (min-width: 1920px) {
  .qr-setting {
    width: calc(100% - 600px);
  }
  .qr-preview {
    overflow-y: scroll;
    width: 600px;
    height: 100%;
  }
}
@media (max-width: 600px) {
  .qr-preview {
    width: 100%;
    height: auto;
    min-height: auto;
  }
}
</style>
