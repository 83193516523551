export const ucfirst = (str: string): string =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : '';

export const emptyString = (str: string): string => str || 'No Name';

export const formatNumberWithComma = (number: number): number | string => {
  const formattedNumber = new Intl.NumberFormat().format(number);
  return number ? formattedNumber : 0;
};

export const sanitizeString = (value: string): string =>
  value
    .replace(/\s\s+/g, ' ') // Remove multiple spaces
    .toLowerCase() // To lower case
    .split(' ') // Split the whenever there's a space
    .join('_') // Join the string using a _
    .replace(/[^a-zA-Z0-9_]/g, ''); // Remove all special characters except underscores

export const formatName = (str: string): string => {
  const parts = str?.split('_');
  const capitalizedParts = parts?.map((part) => {
    if (part.length > 0) {
      return ucfirst(part);
    }
    return part;
  });

  return capitalizedParts.join('');
};
