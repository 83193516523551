import {
  ILinkConfigEmail,
  ILinkConfigLocation,
  ILinkConfigPhone,
  ILinkConfigSms,
  ILinkConfigWifi,
} from '@connect-saas/types';

export const phone = (config: ILinkConfigPhone): string => {
  return `tel:${config.phone}`;
};

export const sms = (config: ILinkConfigSms): string => {
  let uri = `sms:${config.phone}`;
  if (config.message) {
    uri = `${uri}?body=${encodeURIComponent(config.message)}`;
  }

  return uri;
};

export const location = (config: ILinkConfigLocation): string => {
  return `https://maps.google.com/local?q=${config.latitude},${config.longitude}`;
};

export const email = (config: ILinkConfigEmail): string => {
  let uri = `mailto:${config.email}`;

  const params = [];
  if (config.subject) {
    params.push(`subject=${config.subject}`);
  }

  if (config.message) {
    params.push(`body=${encodeURIComponent(config.message)}`);
  }

  if (params.length) {
    uri = `${uri}?${params.join('&')}`;
  }

  return uri;
};

export const wifi = (config: ILinkConfigWifi): string => {
  const { ssid, password, encryption } = config;

  let type = '';
  switch (encryption) {
    case 'wep':
      type = 'WEP';
      break;
    case 'wpa':
      type = 'WPA';
      break;
    case 'none':
    default:
      type = 'blank';
  }

  return `WIFI:S:${ssid};T:${type};P:${password};;`;
};
