import { createRouter, createWebHistory } from 'vue-router';
import GenerateQr from '../views/GenerateQr.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Generate Qr',
      component: GenerateQr,
    },
  ],
});
router.afterEach((to) => {
  const parent = to.matched.find((record) => record.meta.title);
  const parentTitle = parent ? parent.meta.title : null;
  document.title = to.meta.title || parentTitle || 'ConnectQR';
});

export default router;
