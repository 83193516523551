import { defineAsyncComponent, type Component } from 'vue';

const BaseQrUrlConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrUrlConfig.vue'),
);
const BaseQrEmailConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrEmailConfig.vue'),
);
const BaseQrPhoneConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrPhoneConfig.vue'),
);
const BaseQrInstagramConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrInstagramConfig.vue'),
);
const BaseQrSmsConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrSmsConfig.vue'),
);
const BaseQrBusinessCardConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrBusinessCardConfig.vue'),
);
const BaseQrSocialConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrSocialConfig.vue'),
);
const BaseQrFacebookConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrFacebookConfig.vue'),
);
const BaseQrTwitterConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrTwitterConfig.vue'),
);
const BaseQrYoutubeConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrYoutubeConfig.vue'),
);
const BaseQrTiktokConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrTiktokConfig.vue'),
);
const BaseQrWechatConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrWechatConfig.vue'),
);
const BaseQrLocationConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrLocationConfig.vue'),
);
const BaseQrTextConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrTextConfig.vue'),
);
const BaseQrLinkedinConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrLinkedinConfig.vue'),
);
const BaseQrSpotifyConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrSpotifyConfig.vue'),
);
const BaseQrWifiConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrWifiConfig.vue'),
);
const BaseQrAppConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrAppConfig.vue'),
);
const BaseQrFormConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrFormConfig.vue'),
);
const BaseQrFileConfig = defineAsyncComponent(
  () => import('../app/components/base/qr/config/BaseQrFileConfig.vue'),
);

export const baseQrConfigComponents: Record<string, Component> = {
  BaseQrUrlConfig,
  BaseQrEmailConfig,
  BaseQrPhoneConfig,
  BaseQrSmsConfig,
  BaseQrBusinessCardConfig,
  BaseQrSocialConfig,
  BaseQrInstagramConfig,
  BaseQrFacebookConfig,
  BaseQrTwitterConfig,
  BaseQrYoutubeConfig,
  BaseQrTiktokConfig,
  BaseQrWechatConfig,
  BaseQrLocationConfig,
  BaseQrTextConfig,
  BaseQrLinkedinConfig,
  BaseQrSpotifyConfig,
  BaseQrWifiConfig,
  BaseQrAppConfig,
  BaseQrFormConfig,
  BaseQrFileConfig,
};

export default baseQrConfigComponents;
